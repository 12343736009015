import React,{ useEffect,useState } from 'react';
import './../rushmoor/rushMoor.css';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchPostalCodes } from '../../redux/Actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { procstep1,procstep2,procstep3,procstep4} from '../../assets/img'
import { setValue } from '../../utility';
import { NavLink } from 'react-router-dom';
import { fetchShopsBasedPincode } from "../../redux/Actions/shopHomeAction";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url=process.env.REACT_APP_BASE_URL;
const formurlDefault="/productLists"
const postalCodesData={data:{
    "Response": {
        "response_code": "1",
        "response_message": "success"
    },
    "data": {
        "covered_postcodes": [
        ]
    }
}
}

const postalCodesSearchData={result:{
    "Response": {
        "response_code": "1",
        "response_message": "success"
    },
    "data": [
        []
    ]
}}

function RushMoor() {
    
    const [postalCodes, setPostalCodes] = useState< any | null>(postalCodesData)
    const [postalCodesSearch, setPostalCodesSearch] = useState< any | null>(postalCodesSearchData)
    const [formUrl, setFormUrl] = useState< any | null>(formurlDefault)

  const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const [currentCodeValue, setcurrentCodeValue] = useState("")
    const dispatch = useDispatch<any>();
    const state: any = useSelector(state => state);
    const shopAreaData: any = useSelector<any>(
        (state) => state?.shopsArea
      );
    const navigate = useNavigate();
   
    const params = useParams<any>();
    useEffect(() => {
   
    const headers = {
          'Accept': 'application/json'
         
          }
        
            axios.get(`${base_url}/api/pincodes_covered_by_district?district=${params.location}`,{
            headers: headers
  
  }).then(e=>{setPostalCodes({data:e.data})
  let areaData = e.data.data.covered_postcodes[0].postcode_area
  setcurrentCodeValue(areaData)
})}, [])

  useEffect(() => {
    if (state) {
        setPostalCodeList(state.postalCodeList);
        // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }

}, [state])

useEffect(() => {
    const area = params.location
   dispatch(fetchShopsBasedPincode(area,currentCodeValue))
}, [currentCodeValue])

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const {value} = e.target;
    const updateValue = value.replace(/\s/g,'')
    if(value.length > 0){
        dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);

}
const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    dispatch(fetchPostalCodes(searchTerm));
};

const shopHomePage = (currentShopHome:any,currentShopName:any)=>{
    localStorage.setItem("currentShopHome",currentShopHome)
    const shopName = currentShopName
    const resultOne = shopName.replace(/\s(.)/g, function($1: string) { return $1.toUpperCase(); })
    const resultTwo = resultOne.replace(/\s/g, '')
    const finalResult = resultTwo.replace(/^(.)/, function($1: string) { return $1.toLowerCase(); });
    navigate(`/shopHome/${finalResult}`);
}   

const shopsDataPostcode = (currentCodeValue:any) =>{
    const area = params.location
   dispatch(fetchShopsBasedPincode(area,currentCodeValue))
}

  
const handleSubmit = (event: any) => {
    event.preventDefault();
    setValue("postalCode",postalCodeValue)
   // alert(`${base_url}/api/get_shop_by_pincode/pincode=${postalCodeValue}`)
      const headers = {
        'Accept': 'application/json'
       
        }
        setPostalCodesSearch({result:null})
          axios.get(`${base_url}/api/get_shop_by_pincode?pincode=${postalCodeValue}`,{
          headers: headers

    }).then(e=>{setPostalCodesSearch({result:e.data})

    
let lengthvar=e.data.Response.response_message;
if (lengthvar=="Sorry, we didn't cover your postal code yet! Please check for another postal code")
{   event.preventDefault();
    setFormUrl('/areanotcovered')
  //  alert(JSON.stringify( e.data.data[0].length.toString))
    navigate(`/areanotcovered`);
 
    //window.open(`${client_base_url}/areanotcovered`); 
    return false
}
else if (lengthvar=="success")
{
    setFormUrl('/productLists')
    
    navigate('/productLists'); 
}


})

     // alert(`Please enter a valid postal code`)
    }

    return (
        <div>
            <div className="area-covered" id="available-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="area-title text-center">
                                <h1><span>Laundry Services &amp; Dry Cleaners in </span> {params.location?.replace(/-/g, ' ')} </h1>
                            </div>
                            <form method="get" onSubmit={handleSubmit}  >
                            <div className="form-search">
                                <div className="area-search-form">
                                    <div className="input-group ct-area-01 my-32">
                                        <input placeholder="Enter Post code" className="form-control area-input-form" id="postcode-input" value={postalCodeValue} name="pincode"  onChange={handleChange} autoComplete="off" />
                                        <div className="dropdown" id="dropdownrow">
                                            {postalCodeList
                                                .filter((item: any) => {
                                                    const searchTerm = postalCodeValue.toLowerCase();
                                                    const mainValue = item.postcode.toLowerCase();

                                                    return (
                                                        searchTerm &&
                                                        mainValue.startsWith(searchTerm) &&
                                                        mainValue !== searchTerm
                                                    );
                                                })
                                                .slice(0, 10)
                                                .map((item: any) => (
                                                    <div
                                                        onClick={() => onSearch(item.postcode)}
                                                        className="dropdown-row"                                      
                                                        key={item.postcode}
                                                    >
                                                        <i className="fas fa-map-marker-alt"></i>{item.postcode}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="another-search-area text-center">
                            
                                    <button className="submit-area-btn submitCode"  id="book_now_btn" type="submit">Order Now</button>
                                    
                                </div>
                            </div>
                            <div className="area-list-01 text-center">
                                <h3 className="bold">We Covered the below area pincodes</h3>
                                <div className="arealist-slider wow fadeInDown">
                                    <div className="arcode-slider">
                                    <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 4,
                        },
                        1024: {
                          slidesPerView: 6,
                        },
                      }}
                      >
                                        
                                    {
                                        
                                        
                                    postalCodes.data.data.covered_postcodes.map((covered_postcode:any) =>
                                    

                                    { 
                                    return (
                                        <SwiperSlide>
                                    <a href='javascript:void(0)' onClick={() => shopsDataPostcode(covered_postcode.postcode_area)}> { covered_postcode.postcode_area}</a>
                                    </SwiperSlide>
                                    )                         
                                    }
                                    ) 
                                        }
                                        </Swiper>
                                        
                                        
                                    </div>
                                </div>
                            </div>


                            </form>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-content-wrapper my-77">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className='areacoverd-p'>
                                <p>We are the Laundry &amp; Dry Cleaners in {params.location?.replace(/-/g, ' ')}. The Westies Dry Cleaners assures to give the most effective and
                                    powerful laundry and delivery services such as wash, tumble dry and fold.
                                    You can approach from anywhere by press a single button and easy to
                                    download the app.
                                </p>
                                <p>Westies Dry Cleaners gets the clean ironing cloths like shirts, pants and blouses.
                                    You have option to choose what type of fold which you want. You can
                                    order through Westies Dry Cleaners and give your clothes for pick up then get the
                                    fine services in {params.location?.replace(/-/g, ' ')} region.
                                </p>
                            </div>
                            <div className='areacoverd-proc-steps'>
                                <h2>Procedures to be followed</h2>
                                <ul>
                                    <li><span className='prco-img'><img src={procstep1}/></span><span className='prco-desc'>Step 1<h4>Tap</h4></span></li>
                                    <li><span className='prco-img'><img src={procstep2}/></span><span className='prco-desc'>Step 2<h4>Wash</h4></span></li>
                                    <li><span className='prco-img'><img src={procstep3}/></span><span className='prco-desc'>Step 3<h4>Fold</h4></span></li>
                                    <li><span className='prco-img'><img src={procstep4}/></span><span className='prco-desc'>Step 4<h4>Deliver</h4></span></li>
                                </ul>
                            </div>
                            <div className='areacoverd-qorder'>                                
                                <p>You can just tap in the app for getting the needs of laundry and dry cleaning.</p>
                                <a href="javascript:void(0);">Quick Order</a>
                            </div>
                            <div className='areacoverd-footer text-center'>    
                                <h3>Best Dry Cleaning &amp; Laundry service in <span>{params.location?.replace(/-/g, ' ')}</span></h3>
                                <p>One of the best dry cleaning apps is Westies Dry Cleaners that provides the
                                    services with collection and delivery for free. We offer many services
                                    through online that will be best for tracking. Every order is simple and
                                    easy in Westies Dry Cleaners because you can track any details also award
                                    winning app. It is more reliable and trustworthy app.
                                </p>
                                <div className="row justify-content-center mt-4">

                                {shopAreaData?.shops?.map((shops:any) =>
                                        { return <div className="col-lg-3 col-md-6">
                                        <div className="dry-group">
                                        
                                        <div className="dry-cleaner-head">
                                        {/* <img src="https://www.bestatlaundry.om/storage/general/img/shops/homepage/logo/shop_9.png" className="img-m" alt="Shop Logo"/>c */}
                                        <h4>{shops?.name}</h4>
                                    </div>
                                            <div className="dry-cleaner-body">
                                                <div className="address-dry">
                                                    <i className="fa-solid fa-location-dot"></i>
                                                    <p><i className="fas fa-map-marked-alt me-1"></i>{shops?.address?.line_1}, {shops?.address?.line_2}, {shops?.address?.line_3}</p>
                                                    <p className="dry-contact"><i className="fas fa-phone-alt me-1"></i>{shops?.address?.mobile_number}</p>
                                                </div>
                                                
                                                <div className="visit-web btn-m">
                                                    <button className="btn hover-btn" onClick={() => shopHomePage(shops?.id,shops?.name)}>Visit Website</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                        )}
                                    {/* <div className="col-lg-3 col-md-6">
                                        <div className="dry-group">
                                        
                                        <div className="dry-cleaner-head">
                                        <img src="https://www.bestatlaundry.com/storage/general/img/shops/homepage/logo/shop_9.png" className="img-m" alt="Shop Logo"/>
                                        <h4>Laundry Today </h4>
                                    </div>
                                            <div className="dry-cleaner-body">
                                                <div className="address-dry">
                                                    <i className="fa-solid fa-location-dot"></i>
                                                    <p><i className="fas fa-map-marked-alt me-1"></i>Central London, London,UK</p>
                                                    <p className="dry-contact"><i className="fas fa-phone-alt me-1"></i>08000029446</p>
                                                </div>
                                                
                                                <div className="visit-web btn-m">
                                                    <NavLink to={'./../../shopHome'} className="btn hover-btn">Visit Website</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RushMoor;