import { Component } from "react";

class Whatsapp extends Component {
  render() {
    return (
      <div class="link-whatsapp">
        <a target="blank" href="https://wa.me/message/3CVTHF6QR6ZWH1?src=qr">
          {" "}
          <i class="fab fa-whatsapp"></i>
        </a>
      </div>
    );
  }
}

export default Whatsapp;
