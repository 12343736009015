import React from 'react'
import {aboutimg,abouticon1,abouticon2,abouticon3,abouticon4} from '../../assets/img'

function AboutBestAtLaundry() {
  
  return (
    <div>
      <section className="aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-none d-md-block">
              <div className="about-img-left aos-init" data-aos="zoom-in-up">
                <img src={aboutimg} className="img-fluid aos-init" alt="" data-aos="zoom-in-up" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-content-right aos-init" data-aos="zoom-in-up">
                <div className="section-title">
                  <h2 className="text-start">WESTIES DRY CLEANING</h2>
                  <h1>Benefits of Dry Cleaning</h1>
                  <div className="section-line">
                    <span className='first-line'></span>
                  </div>
                  <div className="section-line small-line">
                    <span className='second-line'></span>
                  </div>
                </div>

                {/* <p>When we first opened our laundry, we wanted to be able to offer the best service in London and wigmore. To be the best, we had to buy the best. So we went ahead and invested in the finest garment cleaning machinery available. We have our own in-house jacket machine, trouser machine, shirt machine and ironing table. We don’t sub-contract anything. Your garments come from you, to us, and back to you.</p> */}
                <div className="about-icon">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon1} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Better Stain Removal</h5>
                          <p>One of the best reasons to use a dry cleaner is the superior stain removal the process offers. A dry cleaner with experience knows how to properly remove stains from garments.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon2} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Protect Clothing from Damage</h5>
                          <p>Another advantage to a dry cleaning service is the protection it offers clothing. Quality garments and favourite items can last years longer with regular dry cleaning. The process causes a lot less damage over time compared to a standard home washing machine.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon3} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Convenience - Pickup & Delivery</h5>
                          <p>Many people who lead busy lives can enjoy the convenience that a dry cleaner has to offer. Clothing is dropped off and picked up at a convenient time, cleaned, ironed and bagged.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="media d-flex">
                        <img src={abouticon4} alt="About Icon" />
                        <div className="media-body flex-grow-1">
                          <h5>Professional Appearance</h5>
                          <p>Clothing is always returned ironed on a hanger, usually in a protective plastic bag. A special finishing process is used that makes clothes crisp and wrinkle free with a brand new appearance.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
    </div>
  )
}

export default AboutBestAtLaundry