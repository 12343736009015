import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { useStripe, useElements } from "@stripe/react-stripe-js";
import CardForm from "../cardForm";

import { addDays } from "date-fns";
import {
  fetchPickupAdress,
  fetchPickupDates,
  fetchPickupTimeSlotsDeliveryDates,
  fetchDeliveryTimeSlots,
  fetchPaymentMethods,
  fetchOfferDetailsList,
  fetchReferralData,
  fetchSaveAddress,
  fetchCreateSetUpIntent,
  fetchSubmitOrder,
  fetchUpdateAddress,
  fetchAddressByPincode,
  fetchGenarateOrderLink,
  fetchListSavedCards,
  fetchGetShopByPinCode,
  fetchPickupDelivery,
  fetchCompleteOrderData
} from "../../redux/Actions/checkoutPageActions";

import { setPickupTimesDeliveryDates } from "./../../redux/Actions/checkoutPageActions/index";
import Payment from "../payment";
import { log } from "console";
import { fetchMyOrders } from "../../redux/Actions/myOrderAction";
import GenerateOrderLink from "../generateOrderLink";
import { toast, ToastContainer } from "react-toastify";
import { setValue } from "../../utility";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import { fetchProductAllCategories } from "../../redux/Actions/productCategoriesAction";
import { getValue } from "@testing-library/user-event/dist/utils";
import { setOrderType } from './../../redux/Actions/cartCountAction';
import { pickup,picktime,debit,acceptablepayment,offers } from '../../assets/img'
import { useSearchParams } from "react-router-dom";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";


const formatDate = (date:any) => {
  let selectedDate = new Date(date)
  const yyyy = selectedDate.getFullYear();
let mm:any = selectedDate.getMonth() + 1; // Months start at 0!
let dd:any = selectedDate.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = dd + '-' + mm + '-' + yyyy; 
return formattedToday;
}


const CheckOut = () => {

  // const notify = (message: string) => toast(message);
  const [searchParams, setSearchParams] = useSearchParams();
  const [edit, setEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [couponCode, setCouponCode] = useState<any>("");
  const [couponCodeOffer, setCouponCodeOffer] = useState<any>(null);
  const [couponCodeDetails, setCouponCodeDetails] = useState<any>([]);
  const [updateAdress, setUpdateAdress] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [address, setAddress] = useState<any>([]);
  const [savedAddress, setSaveAddress] = useState<any>([]);
  const state: any = useSelector(state => state);

  const [intent_secret, setIntentSecret] = useState<any>("");

  // ({
  //   startDate:"",
  //   selectedPickupTimeSlot:{},
  //     selectedDeliveryDate:"",
  //     selectedDeliveryTimeSlot: {}
  // })

  // const [cardElement, setCardElement] = useState<any>()

  const [updatePickupDeliveryAddress, setUpdatePickupDeliveryAddress] =
    useState<any>([]);

  const [date, setDate] = useState<any>(new Date());
  const [selectedPickupTimeSlot, setSelectedPickupTimeSlot] = useState<any>();
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<any>(
    new Date()
  );
  const [secondType, setSecondType] = useState(false);
  // below is for the dropdown list
  const [pickupAdress, setPickupAdress] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [pickUpTimeSlots, setPickUpTimeSlots] = useState([]);
  const [deliveryDatesList, setDeliveryDatesList] = useState([]);
  const [addressPincode, setAddressPincode] = useState<any>([]);
  const [postalCodes, setPostalCode] = useState<any>([]);
  const [referralData, setReferralData] = useState<any>(false);
  const [couponError, setCouponError] = useState<any>(false);
  const [selectedUpdatedAddress, setSelectedUpdatedAddress] = useState<any>("");
  const [updateAddressList, setUpdateAddressList] = useState<any>();
  const [cardStatus, setCardStatus] = useState<any>(0);
  const [cardId, setCardId] = useState<any>(0);
  const [selectedPickupAddress, setSelectedPickupAddress] = useState({
    label: "",
    value: "",
    address_id:""
  });
  const [generateLink, setGenerateLink] = useState<any>(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const [submitOrderResponse, setSubmitOrder] = useState<any>();

  const dispatch = useDispatch<any>();
  const pickUpAddressList: any = useSelector<any>(
    (state) => state?.pickupAdress
  );
  const order_type: any = useSelector<any>(
    (state) => state?.orderType
  );
  const completed_Order: any = useSelector<any>(
    (state) => state?.completeOrderData
  );

  const settings: any = useSelector<any>(
    (state) => state.settings
  );

  // const myOrder: any = useSelector<any>(
  //   (state) => state?.myOrderList?.data?.orders_list[0]?.order_type
  // );


  useEffect(() => {
  let order_id = searchParams.get("order_id")
  const userInfo = JSON.parse(localStorage.getItem('userDetails')!);

    
  if(order_id != null){
    if(userInfo != null){
      dispatch(fetchCompleteOrderData({ order_id })).then((data:any)=>{
        if(!data){
          navigate("/")
          setTimeout(()=>{
            toast('Invalid user for this order link');
          },2000)
        }
        let payload = data.data.data
        localStorage.setItem("postalCode",JSON.stringify(payload.postal_code));
        let finalTotalPrice = payload.total_price,
         itemsCount = payload.cart_total_count,
         cartInformationData = JSON.parse(payload.cartInformationData)
        setCartInformation(cartInformationData);
        dispatch(fetchAddToCart(payload));
        localStorage.setItem("completeorderlogin","Yes")
        let postalCode = localStorage.getItem("postalCode")
        navigate("/checkout"); 
        if(postalCode){
        dispatch(fetchGetShopByPinCode(postalCode)).then((data:any)=>{
          const order_type = localStorage.getItem("order_type") || "1"
        const shop_id = data?.data?.shop?.id
        dispatch(fetchPickupDelivery(order_type,shop_id))
          dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
        })
        }

      });
    }else {
      localStorage.setItem("isCompleteOrder", '1');
      localStorage.setItem("order_id", order_id);
      navigate("/login"); 
    }
  }
  }, []);


  useEffect(() => {
    // const order_type = localStorage.getItem("order_type")
    // const shop_id = localStorage.getItem("shop_id")
    // dispatch(fetchPickupDelivery(order_type,shop_id))
    dispatch(fetchMyOrders(10, 1, 0, "all"));
    dispatch(fetchOfferDetailsList());
    // dispatch(setOrderType(1));
    // dispatch(fetchGenarateOrderLink());
    // dispatch(fetchSubmitOrder(payloadSubmitData))
  }, []);

  const addressByPincodes: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );

  const saveAddress: any = useSelector<any>((state) => state?.saveAddress.data);

  useEffect(() => {
    setPickupAdress([...pickupAdress, saveAddress]);
  }, [saveAddress]);

  const paymentMethodsList: any = useSelector<any>(
    (state) => state?.paymentMethods
  );

  const deliveryTimeSlotsList: any = useSelector<any>(
    (state) => state?.deliveryTimeSlots
  );

  const selectedPickupTimeList: any = useSelector<any>(
    (state) => state?.selectedPickupTime
  );
  const selectedDeliveryDateList: any = useSelector<any>(
    (state) => state?.selectedDeliveryDate
  );
  const selectedDeliveryTimeList: any = useSelector<any>(
    (state) => state?.selectedDeliveryTime
  );
  
  const [selectedDeliveryTimeSlot, setSelectedDeliveryTimeSlot] =
    useState<any>();

  const [deliveryTimeSlots, setDeliveryTimeSlots] = useState([]);

  const getShopByPincode: any = useSelector<any>(
    (state) => state.getShopByPinCode.data
  );

  const [cartInformation, setCartInformation] = useState([]);

  useEffect(() => {
    dispatch(fetchAddressByPincode());
    
  }, []);

  useEffect(() => {
    if (deliveryTimeSlotsList) {
      const delTimeSlots = deliveryTimeSlotsList?.data?.delivery_timeslots;
      setDeliveryTimeSlots(delTimeSlots);
    }
  }, [deliveryTimeSlotsList]);

  useEffect(() => {
    if (selectedPickupTimeSlot) {
      const pickupTimeSlots = selectedPickupTimeSlot;
      setSelectedPickupTimeSlot(pickupTimeSlots);
    }
  }, [selectedPickupTimeSlot]);

  // const selectedCategories: any = useSelector<any>(
  //   (state: any) => state.userSelectedCategories
  // );
  const selectedCategories: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );
  const pickupDatesList: any = useSelector<any>(
    (state) => state.pickupDates?.data?.pickup_dates
  );

  const pickupTimesList: any = useSelector<any>(
    (state) => state.pickupTimesDeliveryDates
  );

  let postalCode:any = localStorage.getItem("postalCode");

  const updatedAddressList: any = useSelector<any>(
    (state) => state.updateAddress?.data?.address_details
  );

  useEffect(() => {
    setUpdateAddressList(updatedAddressList);
  }, [updatedAddressList]);

  const createSetupIntent: any = useSelector<any>(
    (state) => state.createSetUpIntent?.data?.intent_client_secret
  );

  const offersDetailList: any = useSelector<any>((state) => state.offersList);
  const referralDetailData: any = useSelector<any>((state) => state.referralData);

  useEffect(() => {
    if (pickupTimesList) {
      const pickUpTimeSlots = pickupTimesList?.data?.pickup_timeslots;
      const deliveryDatesList = pickupTimesList.data?.delivery_dates;

      let firstDeliveryDate = deliveryDatesList
      for(var i in deliveryDatesList){
        firstDeliveryDate = deliveryDatesList[i].date;
        break
      }
      // const newDeliveryDate = new Date(firstDeliveryDate)
      setPickUpTimeSlots(pickUpTimeSlots);
      setDeliveryDatesList(deliveryDatesList);
      setSelectedDeliveryDate(firstDeliveryDate);
    }
  }, [pickupTimesList]);

  

  useEffect(() => {
var dateObj = new Date();
var month = dateObj.getUTCMonth() + 1; //months from 1-12
var day = dateObj.getUTCDate();
var year = dateObj.getUTCFullYear();

    const startDate = year + "-" + month + "-" + day;
    const selectedPickupTimeSlot = selectedPickupTimeList
    const selectedDeliveryDate = selectedDeliveryDateList
    const selectedDeliveryTimeSlot = selectedDeliveryTimeList
    if(selectedPickupTimeSlot && selectedPickupTimeSlot?.id){
      // setStartDate(startDate)
      setSelectedPickupTimeSlot(selectedPickupTimeSlot)
      setSelectedDeliveryTimeSlot(selectedDeliveryTimeSlot)
      
      setAddress([
        {
          startDate,
          selectedPickupTimeSlot: { ...selectedPickupTimeSlot },
          selectedDeliveryDate,
          selectedDeliveryTimeSlot: { ...selectedDeliveryTimeSlot },
        },
      ]);
    }
  }, [selectedPickupTimeList]);

  const navigate = useNavigate();

  useEffect(() => {
    const postalCode = localStorage.getItem('postalCode');
    
    dispatch(fetchPickupAdress());
    dispatch(fetchPickupDates());

    dispatch(fetchPaymentMethods());
    dispatch(fetchProductAllCategories());
    if(postalCode){
      dispatch(fetchGetShopByPinCode(postalCode)).then((data:any)=>{
        const order_type = localStorage.getItem("order_type") || "1"
        const shop_id = data?.data?.shop?.id
        dispatch(fetchPickupDelivery(order_type,shop_id))
      });
    }


    // dispatch(fetchListSavedCards());
    
    setReferralData(false)
    setCouponCodeOffer(null)
    setCouponError(false)
  }, []);

  useEffect(() => {
  
    if(selectedCategories && selectedCategories?.category?.length){
    const cartInformationData = selectedCategories?.category.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });
    
    // if(cartInformationData.length){
    //   setValue("cartInformationData",cartInformationData);
      
    // }
    setCartInformation(cartInformationData);
    // const selectedMainCategory = selectedCategories?.filter((item: any) => {
    //   return item?.sub_categories?.some(
    //     (subItem: any) => parseInt(subItem?.quantity) > 0
    //   );
    // });

    let finalTotalPrice = 0;
    let itemsCount = 0;

    selectedCategories?.category?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );
      itemsCount = itemsCount + subcategoryList?.length;
      subcategoryList?.map((subcategory: any) => {
        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) * parseFloat(subcategory?.total_price);
      });
    });
    if(!localStorage.getItem("completeorderlogin")){
      dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
    }
  }
  // let finalTotalPrice = 0;
  // let itemsCount = 0;
  // dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
    //
    // dispatch(fetchGetShopByPinCode());
  }, [selectedCategories]);

  useEffect(() => {

    if (pickUpAddressList?.address_list?.pickup_address?.length > 0) {
      // const {data: {address_list: {pickup_address = []}}} = pickUpAddressList;
      setPickupAdress([...pickUpAddressList?.address_list?.pickup_address]);
      if(pickUpAddressList?.is_last_used_address_available === "0"){
        setSelectedAddress(pickUpAddressList?.address_list?.pickup_address[0]);
      }else{
      setSelectedAddress(pickUpAddressList?.last_pickup_address);
    }
    }
  }, [pickUpAddressList]);
  useEffect(() => {
    if (selectedPickupTimeSlot && selectedDeliveryDate) {

      dispatch(
        fetchDeliveryTimeSlots(selectedPickupTimeSlot?.id, selectedDeliveryDate)
      );
    }
  }, [selectedDeliveryDate]);
  //

  const onAddAddressClickHandler = () => {
    // dispatch(fetchPickupAdress())
    setUpdateAdress(!updateAdress);
    setEdit(false);
    setAddressPincode(addressByPincodes);
    setSelectedPickupAddress(addressByPincodes[0]);
    dispatch(fetchAddressByPincode());
  };

  const onAddressSelectHandler = (event: any) => {
    if (event?.target?.value !== "-1" && addressPincode?.length > 0) {
      const selectedAddress = addressPincode?.find(
        (item: any) => item.label === event?.target?.value
      );


      setSelectedPickupAddress(selectedAddress);
      //setAddressPincode(selAddress);
    } else {
      // setSelectedAddress([]);
      setSelectedPickupAddress({ label: "", value: "",address_id:"" });
    }

    //setPostalCode(postalCode)
  };
  const handleNewCard = (e: any) => {
    setSecondType((prev) => !prev);
  };
  

  const handleSaveAddress = async () => {

    const contact_name = JSON.parse(localStorage.getItem("userDetails")!).data?.user_info.name;
    const mobile_number = JSON.parse(localStorage.getItem("userDetails")!).data?.user_info.mobile;
   
    const address: any =
      selectedPickupAddress &&
      selectedPickupAddress?.label !== "" &&
      selectedPickupAddress?.value?.split(",");
    if (address?.length > 0) {
      // console.log("selectedPickupAddress",selectedPickupAddress)
      const payloadData = {
        address_type: "pickup",
        address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(postalCode)}"}`,
      };
      // setPickupAdress([
      //   ...pickupAdress,
      //   {  text_address: selectedPickupAddress?.label }
      // ]);
      // const payloaUpdatedData = {
      //   address_id: selectedAddress.address_id,
      //   address_details: `{"contact_name":"${selectedAddress.contact_name}",
      //   "mobile_number":"${selectedAddress.mobile_number}",
      //   "line_1":"${selectedAddress.line_1}",
      //   "line_2":"${selectedAddress.line_2}",
      //   "line_3":"${selectedAddress.line_3}",
      //   "line_4":"${selectedAddress.line_4}",
      //   "locality":"${selectedAddress.locality}",
      //   "city":"${selectedAddress.city}",
      //   "county":"${selectedAddress.county}",
      //   "label":"${selectedAddress.label}",
      //   "pincode":"${selectedAddress.postcode}"}`,
      // };
      // 
      const uniqueAddress = await dispatch(fetchSaveAddress(payloadData))
      
      if(uniqueAddress){
      setUpdateAdress(false);
      setEdit(false);
      setSelectedUpdatedAddress({ text_address: selectedPickupAddress?.label,address_id: selectedPickupAddress?.address_id});
      
      const savedAddress = JSON.parse(localStorage.getItem('savedAddress')!);
      localStorage.removeItem('savedAddress');

      setSelectedAddress(savedAddress?.data);
    }


    }
  };

  const onPickUpDateSelectHandler = (date: Date) => {
    const selectedDate = `${date?.getFullYear()}-${
      date?.getMonth() + 1
    }-${date?.getDate()}`;
    setStartDate(selectedDate);
    dispatch(fetchPickupTimeSlotsDeliveryDates(selectedDate));
  };

  const onPickUpTimeSlotHandler = (event: any) => {
    const selectedTimeSlot = pickUpTimeSlots?.find(
      (item: any) => item?.id === event?.target?.value
    );
    setSelectedPickupTimeSlot(selectedTimeSlot);
  };

  const onDeliveryTimeSlotHandler = (event: any) => {
    const selectedTimeSlot = deliveryTimeSlots?.find(
      (item: any) => item?.id === event?.target?.value
    );
    setSelectedDeliveryTimeSlot(selectedTimeSlot);
  };

  const onPickUpTimesDeliveryDateHandler = (date: Date) => {
    const selectedTimeDate = `${date?.getFullYear()}-${
      date?.getMonth() + 1
    }-${date?.getDate()}`;
    setSelectedDeliveryDate(selectedTimeDate);
  };

  const handleOfferList = (event: any) => {
    // setSelectedCouponCode(event?.target?.value);
    const { value } = event.target;
    setCouponCode(value);
    if(event.target.value.length > 0){
      setCouponError(true)
    }else{
      setCouponError(false)
    }
  };

  const onApplyCouponCodeHandler = () => {
    // API call
    dispatch(fetchOfferDetailsList());
    if (offersDetailList.Response?.response_message === "success") {
      const offerDetails = offersDetailList?.data?.offer_details?.filter(
        (item: any) =>
          item?.offer_code.toLowerCase() === couponCode.toLowerCase()
      );

      if (offerDetails?.length > 0) {
      const PriceDetails = JSON.parse(localStorage.getItem("payload") || '')
      const offerId = offersDetailList.data?.offer_details[0].id || "0"
      const itemsCount = PriceDetails.cart_total_count
      const finalTotalPrice = PriceDetails.total_price
      dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount, offerId }));
        setCouponCodeDetails(offerDetails);
        setCouponCodeOffer(true);
      } else {
        setCouponCodeDetails([]);
        setCouponCodeOffer(false);
      }
    }
  };

  const onApplyReferral = () => {
    // API call
    dispatch(fetchReferralData());
    setReferralData(true)
  };

  const handleCardStatus = (event: any) => {
    dispatch(fetchCreateSetUpIntent(cardStatus));
    setCardStatus(event);
  }

  const handleCardId = (event: any) => {
   setCardId(event)
  }

  const handleSavedCard = () => {
    setSecondType((prev) => false);

    cardStatus(1);
    // const intent_secret = localStorage.getItem("setup_intent_secret");
    //  const payment_method = localStorage.getItem("payment_method");
    //     

    //     stripe
    //       .confirmCardSetup(`${intent_secret}`, {
    //         payment_method: `${payment_method}`,
    //       })
    //       .then(function (result: any) {
    //         
    //         localStorage.setItem("setup_intent", result.setupIntent.id);
    //       });
  };

  const handlePickupDeliveryAddress = () => {
    setEdit(false);
    setIsEdit(true);
  };

  const onContinueButtonClickHandler = () => {
    setAddress([
      {
        startDate,
        selectedPickupTimeSlot: { ...selectedPickupTimeSlot },
        selectedDeliveryDate,
        selectedDeliveryTimeSlot: { ...selectedDeliveryTimeSlot },
      },
    ]);
    setSelectedPickupTimeSlot(selectedPickupTimeSlot)
    setSelectedDeliveryTimeSlot(selectedDeliveryTimeSlot)

    setIsEdit(false);
  };

  const orderDetails = cartInformation?.map((item: any) => {
    return {
      main_category_id: item?.main_category_id,
      sub_categories: item?.sub_categories
        ?.filter((subItem: any) => parseInt(subItem.quantity) > 0)
        ?.map((subCategory: any) => {
          let selectedOption = 1;
          subCategory.options.forEach((option: any) => {
            if (option.is_default == 1) {
              selectedOption = option.option_id;
            }
          });
          
          return {
            sub_category_id: subCategory?.sub_category_id,
            quantity: subCategory?.quantity,
            price: subCategory?.total_price * subCategory?.quantity,
            option_selected: selectedOption,
          }

        })


    }


  })

  const onAddressChange = (event: any, item: any) => {
    setSelectedAddress(item);
    setSelectedUpdatedAddress({ text_address: item.text_address,address_id: item.address_id });
  }


  useEffect(() => {
    if (state) {
        setSubmitOrder(state.submitOrder);
    }

}, [state])

  // const setupIntent = localStorage.getItem("setup_intent");
  const shopId = localStorage.getItem("shop_id")

  const onPlaceHolderBtnClickHandler = async (event: any) => {
    // dispatch(fetchCreateSetUpIntent(cardId));
    let setupIntent = localStorage.getItem("setup_intent");
    // const intent_secret = localStorage.getItem("setup_intent_secret");
    // const payment_method = localStorage.getItem("payment_method");
    if (cardStatus == 1) {
      setupIntent = "";
    }
    //  const stripe: any = useStripe();

    //    stripe
    //      .confirmCardSetup(`${intent_secret}`, {
    //        payment_method: `${payment_method}`,
    //      })
    //      .then(function (result: any) {
    //        
    //        localStorage.setItem("setup_intent", result.setupIntent.id);
    //      });

    // postalCode = JSON.parse(postalCode);

    const payloadSubmitData = {
      order_type: order_type, //myOrders?.orders_list?.order_type,
      postal_code: JSON.parse(postalCode),
      group_id: 1,
      shop_id: shopId,
      total_amount: selectedcategoryPriceList?.total_price_amount,
      vat_amount: selectedcategoryPriceList?.vat_amount,
      pickup_date: startDate,
      pickup_time_slot_id: selectedPickupTimeSlot?.id,
      delivery_date: selectedDeliveryDate,
      delivery_time_slot_id: selectedDeliveryTimeSlot?.id,
      pickup_address: selectedAddress?.address_id,
      billing_address: pickUpAddressList?.last_billing_address?.address_id ?? selectedAddress?.address_id,
      card_details: {
        card_status: cardStatus === 1 ? 1 : 0,
        card_id: cardId,
        setup_intent_id: setupIntent,
      },
      device_info: {
        device: "Desktop",
        platform: "Windows",
        browser: navigator?.userAgent,
      },
      applied_offer_id: 0, //TODo
      original_price: 0, // TODO (total_price_amount - only if coupon applied)
      discount_price: 0, //TOdo (total_price_amount - (total_price_amount - offer discount price))
      price: 0, // TODO (total_price_amount - discount_price)
      order_items: orderDetails,
    };
    const offerPayloadSubmitData = {
      order_type: order_type, //myOrders?.orders_list?.order_type,
      postal_code: JSON.parse(postalCode),
      group_id: 1,
      shop_id: shopId,
      total_amount: JSON.stringify((selectedcategoryPriceList?.total_price_amount - selectedcategoryPriceList?.offer_amount)),
      vat_amount: selectedcategoryPriceList?.vat_amount,
      pickup_date: startDate,
      pickup_time_slot_id: selectedPickupTimeSlot?.id,
      delivery_date: selectedDeliveryDate,
      delivery_time_slot_id: selectedDeliveryTimeSlot?.id,
      pickup_address: selectedAddress?.address_id,
      billing_address: pickUpAddressList?.last_billing_address?.address_id ?? selectedAddress?.address_id,
      card_details: {
        card_status: cardStatus === 1 ? 1 : 0,
        card_id: cardId,
        setup_intent_id: setupIntent,
      },
      device_info: {
        device: "Desktop",
        platform: "Windows",
        browser: navigator?.userAgent,
      },
      applied_offer_id: offersDetailList.data?.offer_details[0]?.id, //TODo
      original_price: selectedcategoryPriceList?.total_price_amount, // TODO (total_price_amount - only if coupon applied)
      discount_price: selectedcategoryPriceList?.offer_amount, //TOdo (total_price_amount - (total_price_amount - offer discount price))
      price: JSON.stringify((selectedcategoryPriceList?.total_price_amount - selectedcategoryPriceList?.offer_amount)), // TODO (total_price_amount - discount_price)
      order_items: orderDetails,
    };
    const referralPayloadSubmitData = {
      order_type: order_type, //myOrders?.orders_list?.order_type,
      postal_code: JSON.parse(postalCode),
      group_id: 1,
      shop_id: shopId,
      total_amount: JSON.stringify((selectedcategoryPriceList?.total_price_amount - selectedcategoryPriceList?.offer_amount)),
      vat_amount: selectedcategoryPriceList?.vat_amount,
      pickup_date: startDate,
      pickup_time_slot_id: selectedPickupTimeSlot?.id,
      delivery_date: selectedDeliveryDate,
      delivery_time_slot_id: selectedDeliveryTimeSlot?.id,
      pickup_address: selectedAddress?.address_id,
      billing_address: pickUpAddressList?.last_billing_address?.address_id ?? selectedAddress?.address_id,
      card_details: {
        card_status: cardStatus === 1 ? 1 : 0,
        card_id: cardId,
        setup_intent_id: setupIntent,
      },
      device_info: {
        device: "Desktop",
        platform: "Windows",
        browser: navigator?.userAgent,
      },
      applied_referral_id: "yes",
      applied_offer_id: 0, //TODo
      original_price: selectedcategoryPriceList?.total_price_amount,
      discount_price: selectedcategoryPriceList?.offer_amount,
      price: JSON.stringify((selectedcategoryPriceList?.total_price_amount - selectedcategoryPriceList?.offer_amount)), // TODO (total_price_amount - discount_price)
      order_items: orderDetails,
    };


    //      const intent_secret = localStorage.getItem("setup_intent_secret");
    // const payment_method = localStorage.getItem("payment_method");

    //  stripe
    //    .confirmCardSetup(`${intent_secret}`, {
    //      payment_method: `${payment_method}`,
    //    })
    //    .then(function (result: any) {
    //      
    //      localStorage.setItem("setup_intent", result.setupIntent.id);
    //    });

    if (order_type === undefined) {
      toast('Order type is missing');
      return;
    }
    if (postalCode === undefined) {
      toast('Postal Code is missing');
      return;
    }

    if(!couponCodeOffer && referralData === true){
      var payloadData:any = referralPayloadSubmitData
    }
    else if(!couponCodeOffer && referralData === false){
      payloadData = payloadSubmitData
    }
    else {
      payloadData = offerPayloadSubmitData
    }

    // await dispatch(fetchSubmitOrder(payloadSubmitData));
    await dispatch(fetchSubmitOrder(payloadData));

    // if (submitOrderResponse.Response.response_code == '-1') {
    //   toast(submitOrderResponse.Response.response_message);
    //   return;
    // }else{
    //   navigate("/thankyou");
    // }
    
    const submitOrderResponses = JSON.parse(localStorage.getItem('submitOrderResponses')!);

    localStorage.removeItem('submitOrderResponses');

    if(submitOrderResponses.Response.response_code === '1'){
      navigate("/thankyou");
    }

  };

  /*
  const handlePlaceOrder = () =>{
    navigate('/thankYou');
    dispatch(fetchSubmitOrder(payloadSubmitData))
  } */

  useEffect(()=>{
    let handler = (event: { target: Node | null; })=>{
      if(menuRef.current){
        if(!menuRef.current.contains(event.target)){
          setGenerateLink(false);
        }
      }
    }
    document.addEventListener('mousedown',function name(params:any) {
      handler(params);
    });
  },[]);

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Checkout</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Checkout</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-checkout">
        <div className="container">
          <div className="row row-eq-height list-checkout">
            <div className="col-md-8" id="paymentsteps">
              <div className="menswear-checkout pickupsteps panel">
                <div
                  className="pickupaddress-title"
                  data-parent="#paymentsteps"
                >
                  <div className="media d-flex">
                    <div className="media-left flex-shrink-0">
                      <img
                        src={pickup}
                        className="media-object"
                        alt=""
                      />
                    </div>
                    <div className="media-body flex-grow-1">
                      <h4>Pickup/Delivery Address</h4>
                      <span className="continue-tick">
                        <svg
                          height="14"
                          width="14"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className=""
                        >
                          <path
                            d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                            stroke="#0e8adb"
                          ></path>
                        </svg>
                      </span>
                      {edit == false &&
                       <p className="mb-0 accfullwidth pickup_address_header " hidden={selectedUpdatedAddress?.text_address}>
                        { pickUpAddressList?.last_pickup_address?.text_address || pickUpAddressList?.address_list?.pickup_address[0]?.text_address}
                        {/* { pickUpAddressList?.last_pickup_address?.address_id} */}
                      </p> }
                     
                      <p className="mb-0 accfullwidth pickup_address_header">
                        { selectedUpdatedAddress?.text_address}
                        
                      </p>
                      
                      <a
                        href="javascript:void(0);"
                        className="editbtn pickup_address_header_edit"
                        onClick={() => setEdit(true)}
                      >
                        <i className="fas fa-pencil-alt" aria-hidden="true"></i>{" "}
                        <span
                          className="d-none d-md-inline-block"
                        >
                          Edit
                        </span>
                      </a>
                    </div>
                  </div>
                </div>

                {edit && (
                  <div className="pstepsinner" id="step1">
                    <div className="row row-eq-height" id="saved-address-list">
                      {/* <div className="col-sm-4 mb-14 row-eq-height pickup-address-block">
                        <div className="address-blk-card">
                          <div className="media d-flex">
                            <div className="media-left flex-shrink-0">
                              <label className="container-blk">
                                <input
                                  data-text-address="Flat 1, Park View House, 7 High Street, Aldershot, Hampshire, GU111BH"
                                  className="address-radio-btn"
                                  id="pickup-delivery-1"
                                  // checked={
                                  //   selectedAddress?.text_address ===
                                  //   selectedPickupAddress?.label
                                  // }
                                  name="pickup-radio-group"
                                  type="radio"
                                  value={selectedAddress?.text_address}
                                  onChange={(event: any) => {
                                    if (event?.target?.value) {
                                      //selectedPickupAddress({label: event?.target?.value, value: event.target?.value});
                                      setSelectedUpdatedAddress({ text_address: event?.target?.value });
                                      setSelectedAddress(selectedAddress);
                                    }
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="media-body position-relative flex-grow-1">
                              <p className="mb-0 text-address">
                                {selectedAddress?.text_address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {pickupAdress?.map((item: any) => {
                        return (
                          <>
                          {pickupAdress[0] !== undefined &&
                          <div className="col-sm-4 mb-14 row-eq-height pickup-address-block">
                            <div className="address-blk-card">
                              <div className="media d-flex">

                                <div className="media-left flex-shrink-0">
                                  <label className="container-blk">
                                    <input
                                      data-text-address="Flat 1, Park View House, 7 High Street, Aldershot, Hampshire, GU111BH"
                                      className="address-radio-btn"
                                      id="pickup-delivery-1"
                                      name="pickup-radio-group"
                                      type="radio"
                                      value={item?.address_id}
                                      checked = {item?.address_id == selectedAddress?.address_id}
                                      onClick={(e) => { onAddressChange(e, item) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>

                                <div className="media-body position-relative flex-grow-1">
                                  <p className="mb-0 text-address">
                                    {item?.text_address}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                      }
                        </>
                        );
                      })}
                      <div className="col-sm-4 row-eq-height add_address">
                        <div className="address-blk-card  h-100 position-relative border-dashed-style py-76">
                          <button
                            id="add_new_adrs_btn"
                            className="btn w-100 text-theme no-bg-color position-absolute position-align-center"
                            onClick={() => onAddAddressClickHandler()}
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            Add Address
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-14">
                        <a
                          href="javascript:;"
                          id="addresscontinuebtn"
                          className="btn btn-lg btn-theme address_continue_btn"
                          onClick={handlePickupDeliveryAddress}
                        >
                          CONTINUE
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {updateAdress && (
                  <div className="row" id="add-new-address-form">
                    <div className="col-md-12">
                      <div className="add-address-form">
                        <form>
                          <div className="form-group focused">
                            <div className="row vertical-align">
                              <div className="col-sm-12">
                                <input
                                  placeholder="Pincode"
                                  className="form-control address-form filled"
                                  id="pincode"
                                  required
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="pincode"
                                  disabled
                                  type="text"
                                  value={postalCode}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row vertical-align">
                              <div className="col-sm-12">
                                <select
                                  className="form-select  address-form"
                                  name="select-address"
                                  onChange={onAddressSelectHandler}
                                >
                                  {/* {pickupAdress?.map((item: any) => {
                                    return (
                                      <option
                                        value={item?.address_id}
                                        selected={
                                          selectedAddress?.address_id ===
                                          item?.address_id
                                        }
                                      >
                                        {item?.text_address}
                                      </option>
                                    );
                                  })} */}
                                  {addressPincode?.map(
                                    (item: any, index: any) => (
                                      <option
                                        value={item?.label}
                                        selected={index === 1}
                                      >
                                        {item?.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                <span
                                  className="continue-tick validation-tick address_tick_mark"
                                  style={{
                                    marginRight: "10px",
                                    display: "none",
                                  }}
                                >
                                  <svg
                                    height="14"
                                    width="14"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                                      stroke="#0e8adb"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <span className="pickupaddress-error error-block hidden"></span>
                          </div>
                          {addressPincode?.length === 0 &&
                          <div>
                            <p className= "text-danger">No landmarks found for the selected postal code</p>
                          </div>
                          }
                          <div className="form-group mb-0">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="row text-center">
                                  <div className="col-sm-6">
                                    <button
                                      type="button"
                                      className="btn btn-lg mr-14 btn-theme save_address_continue_btn"
                                      id="save-address-continue-btn"
                                      onClick={handleSaveAddress}
                                    >
                                      Save &amp; Continue
                                    </button>
                                  </div>
                                  <div className="col-sm-6">
                                    <button
                                      id="cancel-new-address-btn"
                                      type="button"
                                      className="btn btn-lg btn-outline-cancel"
                                      onClick={() => setUpdateAdress(false)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="menswear-checkout pickupsteps panel">
                <div
                  className="pickupaddress-title"
                  data-parent="#paymentsteps"
                >
                  <div className="media d-flex">
                    <div className="media-left flex-shrink-0">
                      <img
                        src={picktime}
                        className="media-object j"
                        alt=""
                      />
                    </div>
                    <div className="media-body flex-grow-1">
                      <h4>Pickup/Delivery Time</h4>
                      <span className="continue-tick">
                        <svg
                          height="14"
                          width="14"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          className=""
                        >
                          <path
                            d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                            stroke="#0e8adb"
                          ></path>
                        </svg>
                      </span>
                      <p
                        className="mb-0 accfullwidth pickup_date_header"
                        style={{ display: "block" }}
                      >
                        {address && (
                          <>
                            <span>
                              Pick up on - {formatDate(startDate)}
                              &nbsp;
                              {address[0]?.selectedPickupTimeSlot.timeslot}
                            </span>
                            &nbsp;
                            <span>
                              Delivery on - {formatDate(address[0]?.selectedDeliveryDate)}
                              &nbsp;
                              {address[0]?.selectedDeliveryTimeSlot.timeslot}
                            </span>
                          </>
                        )}
                      </p>

                      {/* <span
                        className="pickup-delivery-error error-block hidden"
                        style={{ fontSize: "x-small" }}
                      >
                        Please select pickup and delivery address
                      </span> */}
                      <a href="javascript:void(0)" className="editbtn pickup_date_header_edit" 
                          onClick={() => setIsEdit(true)}>
                        <i className="fas fa-pencil-alt" aria-hidden="true"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Edit
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                {isEdit == true && (
                  <div className="pstepsinner" id="step2">
                    <div className="row">
                      <div className="col-md-6">
                        <h3 className="h3steps">Choose Pickup Time</h3>
                        <div
                          className="Pickup-date input-group"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="form-group formgrp-custom"
                            style={{ width: "10rem" }}
                          >
                            {/* <input
                              placeholder="Pickup Date"
                              className=" form-control "
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              required
                              name="pickup_date"
                              type="text"
                            /> */}
                            <DatePicker
                              className="datePicker-select"
                              selected={new Date(startDate)}
                              includeDates={pickupDatesList?.map(
                                (item: any) => new Date(item?.date)
                              )}
                              onChange={onPickUpDateSelectHandler}
                            />
                            <span className="continue-tick validation-tick">
                              <svg
                                height="14"
                                width="14"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="tick_mark_svg"
                                style={{ display: "inline" }}
                              >
                                <path
                                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                                  stroke="#0e8adb"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="form-group formgrp-custom"
                            style={{ width: "10rem" }}
                          >
                            <select
                              className="mdb-select valid_time_chk form-select"
                              required
                              name="pickup_time"
                              onChange={onPickUpTimeSlotHandler}
                            >
                              {pickUpTimeSlots?.map((item: any) => (
                                <option
                                  value={item?.id}
                                  selected={
                                    selectedPickupTimeSlot &&
                                    selectedPickupTimeSlot?.id === item?.id
                                  }
                                >
                                  {item.timeslot}
                                </option>
                              ))}
                            </select>
                            <span className="continue-tick validation-tick">
                              <svg
                                height="14"
                                width="14"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="tick_mark_svg"
                                style={{ display: "inline" }}
                              >
                                <path
                                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                                  stroke="#0e8adb"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="h3steps">Choose Delivery Time</h3>
                        <div
                          className="Pickup-date input-group"
                          style={{ width: "100%" }}
                        >
                          <div
                            className="form-group formgrp-custom"
                            style={{ width: "10rem" }}
                          >
                            {/* <input
                              placeholder="Delivery Date"
                              className="delivery-datetimepicker valid_time_chk form-control address-form"
                              aria-label="Small"
                              aria-describedby="inputGroup-sizing-sm"
                              required
                              name="delivery_date"
                              type="text"
                            /> */}
                            <DatePicker
                              className="datePicker-select"
                              selected={new Date(selectedDeliveryDate)}
                              includeDates={deliveryDatesList?.map(
                                (item: any) => new Date(item?.date)
                              )}
                              onChange={onPickUpTimesDeliveryDateHandler}
                            />
                            <span className="continue-tick validation-tick">
                              <svg
                                height="14"
                                width="14"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="tick_mark_svg"
                                style={{ display: "inline" }}
                              >
                                <path
                                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                                  stroke="#0e8adb"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div
                            className="form-group formgrp-custom"
                            style={{ width: "10rem" }}
                          >
                            <select
                              className="mdb-select valid_time_chk form-select"
                              required
                              name="delivery_time"
                              onChange={onDeliveryTimeSlotHandler}
                            >
                              {deliveryTimeSlots?.map((item: any) => {
                                return (
                                  <option
                                    value={item?.id}
                                    selected={
                                      selectedDeliveryTimeSlot &&
                                      selectedDeliveryTimeSlot?.id === item?.id
                                    }
                                  >
                                    {item.timeslot}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="continue-tick validation-tick">
                              <svg
                                height="14"
                                width="14"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="tick_mark_svg"
                                style={{ display: "inline" }}
                              >
                                <path
                                  d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                                  stroke="#0e8adb"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span
                      className="pickupdate-error error-block hidden"
                      style={{ display: "none" }}
                    ></span>
                    <span className="deliverydate-error error-block hidden"></span>
                    <a
                      href="#"
                      id="addresscontinuebtn"
                      className="btn btn-lg btn-theme date_continue_btn"
                      onClick={onContinueButtonClickHandler}
                    >
                      CONTINUE
                    </a>
                  </div>
                )}
              </div>
              <div className="menswear-checkout pickupsteps panel">
                <div
                  className="pickupaddress-title"
                  data-parent="#paymentsteps"
                >
                  <div className="media d-flex">
                    <div className="media-left flex-shrink-0">
                      <img
                        src={debit}
                        className="media-object"
                        alt=""
                      />
                    </div>
                    <div className="media-body flex-grow-1">
                      <h4>Payment Methods</h4>
                    </div>
                  </div>
                </div>
                <div className="pstepsinner" id="step3">
                  <div className="user_cards">
                    <div className="price-details">
                      <div className="">
                        {paymentMethodsList?.card_enabled === "1" && (
                          <>
                            <Payment
                              onPlaceHolderBtnClickHandler={
                                onPlaceHolderBtnClickHandler
                              }
                              cardStatus={handleCardStatus}
                              cardId = {handleCardId}
                            />
                            </>
                            )}


                        {paymentMethodsList?.card_enabled === "0" && (
                          <div className="row"> COD</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="acc-payments">
                    <img
                      src={acceptablepayment}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="mt-3">
                    <div className="alert alert-info">
                      <p>
                        We are using strong SSL ciphers and we won't save any
                        critical card information. Payments are powered by
                        PCI-certified{" "}
                        <a
                          target="_blank"
                          href="https://stripe.com/docs/security/stripe"
                        >
                          Stripe Payment Gateway
                        </a>
                        . If you have any questions, or encounter any issues,
                        please{" "}
                        <a target="_blank" href="tel:+442086388690">
                          let us know.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 payment-option">
              <div className="summary-payment">
                <div className="price-details amt-payable">
                  <div className="price-header text-center">
                    <h2>Order Summary</h2>
                  </div>
                  <div className="price-body">
                    {cartInformation?.map((item: any) => {
                      return (
                        <>
                          <div className="cs-option-price">
                            <span className="cs-summary-title">
                              <h4>{item?.main_category_name}</h4>
                            </span>
                            {item?.sub_categories
                              ?.filter(
                                (subItem: any) => parseInt(subItem.quantity) > 0
                              )
                              ?.map((subCategory: any) => {
                                return (
                                  <div className="cs-option-body">
                                    <div className="cs-option-left">
                                    <span className="theme-clr-primory">{subCategory?.sub_category_name}{" "}</span>
                                    <span className="cs-option-pqty">({subCategory?.quantity})</span>
                                    </div>
                                    <div className="cs-option-right">
                                    {settings?.WebmasterSettings?.currency}&nbsp;{parseInt(subCategory?.quantity) *parseFloat(subCategory?.total_price)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      );
                    })}
                    <div className="cs-option-amt">
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">Amount</div>
                        <div className="cs-option-amt-right">{settings?.WebmasterSettings?.currency} {selectedcategoryPriceList?.total_price_amount}</div>
                      </div>
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">Amount without VAT</div>
                        <div className="cs-option-amt-right">
                        {settings?.WebmasterSettings?.currency} {(parseFloat(
                          selectedcategoryPriceList?.total_price_amount
                        ) - parseFloat(selectedcategoryPriceList?.vat_amount)).toFixed(2)}
                        </div>
                      </div>
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">VAT{" "}({selectedcategoryPriceList?.vat_percentage}%)</div>
                        <div className="cs-option-amt-right">{settings?.WebmasterSettings?.currency} {selectedcategoryPriceList?.vat_amount}</div>
                      </div>
                      {selectedcategoryPriceList.offer_amount > 0 &&
                      selectedcategoryPriceList.offer_percentage > "0" &&
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">Offer{" "}({selectedcategoryPriceList?.offer_percentage}%)</div>
                        <div className="cs-option-amt-right">(-){settings?.WebmasterSettings?.currency} {selectedcategoryPriceList?.offer_amount}</div>
                      </div>
                      }
                      
                      {selectedcategoryPriceList.offer_percentage === "0" &&
                      selectedcategoryPriceList.offer_amount > 0 &&
                      <div className="cs-option-amt-list">
                        <div className="cs-option-amt-left">Referral Offer</div>
                        <div className="cs-option-amt-right">(-){settings?.WebmasterSettings?.currency} {selectedcategoryPriceList?.offer_amount}</div>
                      </div>
                      }
                      <div className="cs-option-amt-list cs-option-amt-listbg">
                        <div className="cs-option-amt-left">Amount Payable</div>
                        <div className="cs-option-amt-right">{settings?.WebmasterSettings?.currency} {selectedcategoryPriceList?.final_payable_amount}</div>
                      </div>
                    </div>
                  </div>
                  
                  {/* <div className="text-center" style={{ color: "#0D8ADA" }}>
                    <small>
                      <b>Note:</b> Minimum order amount is{" "}
                      {getShopByPincode?.price_currency}
                      {getShopByPincode?.minimum_order_amount}
                    </small>
               </div> */}
                  <div className="coupon-code my-20 mt-3">
                    {referralData === false &&
                    <div className="input-group">
                      <input
                        id="couponCode"
                        className="form-control form-coupon"
                        placeholder="Enter Coupon Code"
                        type="text"
                        onChange={handleOfferList}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-offer"
                          type="button"
                          id="applyCoupon"
                          disabled={couponCodeOffer}
                          onClick={onApplyCouponCodeHandler}
                        >
                          {!couponCodeOffer ? "Apply" : "Applied"}
                        </button>
                      </div>
                    </div>
                    }
                    {couponCodeOffer && (
                      <div
                        className="offers aos-init"
                        data-aos="flip-down"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="offers-title">
                          <img
                            src={offers}
                            className="img-fluid"
                            alt=""
                          />
                          <p>Offers</p>
                        </div>
                        <div className="offers-content offers-div">
                          {couponCodeDetails.map((item: any) => {
                            return (
                              <div
                                className="ofs-details "
                                id={item?.id}
                                data-id={item?.id}
                                data-percentage={item?.percentage}
                              >
                                <div className="row">
                                  <div className="ofs-details-lft col-md-9 col-xs-9">
                                    <h4>{item?.title}</h4>
                                    <p
                                      data-placement="left"
                                      data-bs-toggle="tooltip"
                                      title={item?.title}
                                    >
                                      {item?.description}
                                    </p>
                                  </div>
                                  <div className="col-md-3 col-xs-3 ofs-details-rht">
                                    <p className="total-discount">
                                      {item?.percentage}
                                    </p>
                                  </div>
                                </div>
                                <div className="row coupon-ofs">
                                  <div className="col-md-12">
                                    <span>{item?.offer_code}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {couponCodeOffer !== null &&
                      !couponCodeOffer &&
                      couponCodeDetails?.length === 0 &&
                      couponError && (
                        <div
                          className="text-center text-danger offer_not_valid"
                          style={{ marginTop: "10px" }}
                        >
                          Invalid Coupon Code
                        </div>
                      )}
                      {couponCodeOffer !== null &&
                      !couponCodeOffer &&
                      couponCodeDetails?.length === 0 &&
                        !couponError && (
                        <div
                          className="text-center text-danger offer_not_valid"
                          style={{ marginTop: "10px" }}
                        >
                          Enter Coupon Code
                        </div>
                      )}
                  </div>
                  {offersDetailList.data?.refer_details?.usableBalance > 0 &&
                  order_type !== 1 &&
                  !couponCodeOffer &&
                  <>
                  <div className="coupon-code my-20 mt-3">
                    <div className="input-group">
                      <input
                        id="couponCode"
                        className="form-control form-coupon"
                        type="text"
                        value={offersDetailList.data?.refer_details?.usableBalance}
                        readOnly
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-offer"
                          type="button"
                          id="applyCoupon"
                          disabled={referralData === true}
                          onClick={onApplyReferral}
                        >
                          {referralData === false ? "Apply Referral" : "Applied Referral"}
                        </button>
                      </div>
                    </div>
                    </div>
                    </>
                  }
                  {/* <div className="clearfix"></div>
                            <hr />
                            <div className="input-group">
                                        <input id="referalVal" className="form-control form-coupon" placeholder="Enter Referal" type="text" value="{{$totalreferal}}" readOnly />

                                        <div className="input-group-btn">
                                            <button className="btn btn-offer" type="button" id="applyReferrel">Apply Referrel</button>
                                        </div>
                                    </div>
                             <div className="referrals" data-aos="flip-down">
                                        <div className="offers-title">
                                            <img src="{{ URL::to('frontEnd/img/pickup/offers.png') }}" className="img-responsive" alt="" />
                                            <p>Referrals</p>
                                        </div>

                                        <div className="offers-content offers-div">
                                            Referrals succesfully applied {settings?.WebmasterSettings?.currency}{{$totalreferal}}
                                        </div>
                                    </div> */}
                  <div
                    className="text-center pt-10"
                    style={{ color: "#0D8ADA" }}
                    onClick={() => setGenerateLink(!generateLink)}
                  >
                    <small id="save_request" style={{ cursor: "pointer" }}>
                      Click here to generate order link
                    </small>
                  </div>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {generateLink && <GenerateOrderLink close= {()=>setGenerateLink(false)}/>}
      <ToastContainer />
    </div>
  );
};

export default CheckOut;
