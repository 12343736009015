import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h1 className="text-start">We offer free pick up and delivery</h1>
</div>
<div className="">
<p>Get your clothes dry cleaned hassle-free. Our team picks up the clothes from your doorstep, cleans them, and again delivers the same and all this without an extra penny. Contact us to avail of this facility.</p>
{/* <p>We also make sure we only ever use the best detergents and other cleaning materials. And it is this combination of advanced machinery, and top quality consumables that makes us the best laundry service in the area.</p>
<p>If you have ever taken your garments to a cheap cleaning service, and they have come back without stains being removed, still smell, or are not as bright as they could be, this is because the cleaner used cheap detergent and old machinery. We believe that you get what you pay for. We use only the best, so that your clothing is returned to you completely clean and in perfect shape.</p> */}
</div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;