import React from 'react'

function openingHours() {
    return (
        <div className="opening-hours-grid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 aos-init text-center opening-hours-grid-header" data-aos="zoom-in-up">                            
                            <h2>Opening Hours</h2>  
                            <h4>MAIDENHEAD & BURNHAM BRANCH</h4>                         
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 aos-init text-center" data-aos="zoom-in-up">  
                            <div className="opening-hours-wrap"> 
                                <h3>Counter Services Dry Cleaning</h3>
                                <ul className="opening-hours-list">
                                    <li> 
                                        <span className='ophrs-day'>Monday - Friday</span>
                                        <span className='ophrs-time'>9.00 am to 5.00 pm</span>
                                    </li>
                                    <li> 
                                        <span className='ophrs-day'>Saturday</span>
                                        <span className='ophrs-time'>9.00 am to 1.00 pm</span>   
                                    </li>   
                                    <li> 
                                        <span className='ophrs-day'>Sunday</span>
                                        <span className='ophrs-time'>9.00 am to 3.00 pm</span>   
                                    </li>   
                                </ul>                           
                            </div>                      
                        </div>
                        <div className="col-md-6 aos-init text-center" data-aos="zoom-in-up">  
                            <div className="opening-hours-wrap"> 
                                <h3>Laundrette Counter</h3>
                                <ul className="opening-hours-list">
                                    <li> 
                                        <span className='ophrs-day'>Monday - Friday</span>
                                        <span className='ophrs-time'>9.00 am to 5.00 pm</span>
                                    </li>
                                    <li> 
                                        <span className='ophrs-day'>Saturday</span>
                                        <span className='ophrs-time'>9.00 am to 4.00 pm</span>   
                                    </li>   
                                    <li> 
                                        <span className='ophrs-day'>Sunday</span>
                                        <span className='ophrs-time'>9.00 am to 3.00 pm</span>   
                                    </li>   
                                </ul>                           
                            </div>                      
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default openingHours