import React, { useEffect, useRef, useState } from 'react';
import { Button, FormLabel } from 'react-bootstrap';
import './../profile/profile.css';
import { useNavigate } from 'react-router-dom';
import { myprofile,logout} from '../../assets/img'

function Profile(close:any) {
  const navigate = useNavigate();
  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login")
    window.location.reload();
  }

  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState<any>("");
  const popupRef = useRef<any>(null);

  useEffect(() => {
    if (localStorage.getItem("userDetails")) {
      const userInfo = JSON.parse(localStorage.getItem('userDetails')!);

      const { data } = userInfo;

      if (data) {
        const user_info = data?.user_info;
        const { name = "" } = user_info;
        // const token = getToken();
        setUserName(name);
      }

      // setUserToken(token);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event:any) {
      if (!popupRef.current.contains(event.target))  {
        // close();
        
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ul ref={popupRef}>
    {!userName.includes('(Guest)') ?
      <div>
        <li className="presentation" onClick={() => navigate("/myProfile")}>
          <a href='#' className='menuitem' role="menuitem"><i className="fas fa-user-circle me-2"></i>My Profile</a>
        </li>
        <li className="presentation" onClick={() => navigate("/referFriend")}>
          <a href="#" className='menuitem' role="menuitem"><i className="fas fa-user-plus me-2"></i>Refer a friend</a>
        </li>
      </div> : <span></span>}
      <li className="presentation">
        <a className='menuitem' href='#' onClick={(e) => handleLogout(e)} ><i className="fas fa-sign-out-alt me-2"></i>Log Out</a>
      </li>
    </ul>
    
  );
}

export default Profile;
