import { createStore, applyMiddleware, combineReducers } from "redux";
// import {configureStore} from "@redux"
import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";
import RootsReducer from "./Reducers";


export default configureStore({
    reducer: 
        RootsReducer
    
  });
  

